import BoxContainer from '@components/Components/Reusables/BoxContainerV2'
import { count, getSetting, isDefined } from '@components/config/helpers';
import useWindowSize from '@components/_hooks/useWindowSize';
import dynamic from 'next/dynamic';
import React from 'react'
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import HomeCategories from '../HomeCategories';
import EhHomeAboutUs from './EhHomeAboutUs';
import HomeProductCarousel from '../HomeProductCarousel';
import Typography from '@mui/material/Typography';
import DefaultSkeleton from '@components/Components/Reusables/DefaultSkeleton';
import Divider from '@mui/material/Divider';
import PageTitle from '@components/Components/Reusables/PageTitle';

const VenueSelector = dynamic(() => import("@components/Components/Reusables/VenueSelector"), {ssr: false, loading: () => (
    <>
        <DefaultSkeleton 
            variant="rounded" height={125} 
            sx={{
                marginBottom: {
                    xs: '25px',
                    md: '10px'
                },
                marginTop: {
                    xs: 0,
                    md: '15px'
                }
            }}
        />
    </>
)});

const HomeSlider = dynamic(() => import("../Slider/HomeSlider"));


const categories = [
    {
        category_id: 1,
        name: 'Exhibitors',
        slug: 'exhibitions/browse-furniture',
        subtitle: 'Browse Furniture / Order',
        status: 'enabled',
        visible: 'yes',
        image: `/images/${process.env.brand.acronym}/exhibitors-tile.jpg`,
    },
    {
        category_id: 1,
        name: 'Stand Builders',
        slug: 'exhibitions/stand-builders',
        subtitle: 'Learn More / Register',
        status: 'enabled',
        visible: 'yes',
        image: `/images/${process.env.brand.acronym}/stand-builders-tile.jpg`,
    },
    {
        category_id: 1,
        name: 'Organisers',
        slug: 'exhibitions/organisers',
        subtitle: 'Learn More / Register',
        status: 'enabled',
        visible: 'yes',
        image: `/images/${process.env.brand.acronym}/organisers-tile.jpg`,
    },
]

const Home = ({homeData}) => {

    const { template_settings, template_page_content, } = useSelector((state) => state.globalReducer);
    const { tabs, long_term, template_page_settings, } = homeData;
    const page_content = template_page_content['home'];
    const {isMobile} = useWindowSize();
    const layout = getSetting(
        template_settings,
        template_page_settings,
        "layout",
        "wide"
    );

	
    const { home_grid_columns, home_article_count }= template_page_settings;

    const renderCategories = () => {
		let array = [];
		array[1] = categories;
        return array;
	}

    return (
        <div  id="home" key="home" className="p-0 mb-0 pt-2 hide-horizontal-overflow">
            <BoxContainer className="mb-1" mobileClasses={'p-0 pl-2 pr-2'}>
            

            <PageTitle 
                as="h1" 
                className="text-center fw-semi-bold dark-grey m-1"
                sx={{
                    fontSize: {
                        xs: 15,
                        lg: 30
                    },
                    fontWeight: 500
                }}
            >
                Delivering Furniture to Exhibition Venues for Exhibitors, Stand Builders and Organisers
            </PageTitle>
            {/* <Typography className="mt-2 bold text-center dark-grey" component="h1" as="h1"
                sx={{
                    fontSize: {
                        xs: 16,
                        lg: 28
                    },
                    fontWeight: 500
                }}
                >
                    Delivering Furniture to Exhibition Venues for Exhibitors, Stand Builders and Organisers
                </Typography> */}
                {/* {
                    !isMobile && (
                        <Box>
                            <HomeSlider slides={homeData.slides}/>
                        </Box>
                    )
                } */}
    
                <VenueSelector 
                    elevation={0} 
                    className="mt-3 margin-auto mobile-w-100 w-50" 
                    titleClass='text-center'
                    venueGrid={{
                        xs: 12,
                        md: 12
                    }}
                    showGrid={{
                        xs: 12,
                        md: 6
                    }}
                />

                <Divider className="mb-3" />
    
                <Box className="mb-3">
                    <HomeCategories
                        categories={renderCategories()}
                        home_grid_columns={home_grid_columns}
                        includeBanner={false}
                        custom
                        template_page_settings={template_page_settings}
                    />
                    
                    {/* {page_content.section_1 && <HomeDescription />} */}
                </Box>

                <Box className="mt-4">
                    <EhHomeAboutUs isMobile={isMobile} />
                </Box>

                <Box className={ (layout == 'box' && !isMobile ) || (!isMobile) ? 'boxed-paddin mb-5 mt-6' : ''}> {/* add and debug the boxed-padding*/}
                
					{
						isDefined(tabs) && count(Object.keys(tabs)) > 0 && (
							<HomeProductCarousel 
								tabs={tabs}
								long_term={long_term}
								data={homeData}
                                elevation={0}
							/>
						)
					}
				</Box>

            </BoxContainer>
        </div>

    )
}

export default Home